<template>
  <label class="checkbox" :for="id" :class="{ checked: value }">
    <input
      :id="id"
      :checked="value"
      type="checkbox"
      :disabled="disabled"
      @input="$emit('input', $event.target.checked)"
    />
    {{ label }}
  </label>
</template>

<script>
export default {
  props: {
    label: String,
    id: String,
    value: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
