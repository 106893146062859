<template>
  <section class="container-sm px-1 md:pt-1-half">
    <p>{{ $t('TriageQuestion') }}</p>
    <form>
      <Checkbox
        id="feverChills"
        v-model="symptoms.feverChills"
        :label="$t('FeverOrChills')"
      />
      <Checkbox
        id="difficultyBreathing"
        v-model="symptoms.difficultyBreathing"
        :label="$t('DifficultyBreathing')"
      />
      <Checkbox id="cough" v-model="symptoms.cough" :label="$t('Cough')" />
      <Checkbox
        id="lossOfSenses"
        v-model="symptoms.lossOfSenses"
        :label="$t('LossOfAppetite')"
      />
      <Checkbox
        id="vomitingDiarrhea"
        v-model="symptoms.vomitingDiarrhea"
        :label="$t('VomitingOrDiarrhea')"
      />
      <Checkbox
        id="soreThroat"
        v-model="symptoms.soreThroat"
        :label="$t('SoreThroat')"
      />
      <Checkbox id="aching" v-model="symptoms.aching" :label="$t('Aches')" />
      <Checkbox
        id="fatigue"
        v-model="symptoms.fatigue"
        :label="$t('SevereFatigue')"
      />
      <Checkbox
        id="headache"
        v-model="symptoms.headache"
        :label="$t('NewHeadache')"
      />
      <Checkbox
        id="congestion"
        v-model="symptoms.congestion"
        :label="$t('CongestionRunningNose')"
      />
      <Checkbox id="none" v-model="noSymptoms" :label="$t('NoneOfTheAbove')" />
      <div class="text-center">
        <a
          href="#"
          class="button extra-large mt-2-half"
          data-test="triage-page-continue-button"
          :disabled="!noSymptoms && !symptomsSelected"
          @click.prevent="determineQualification()"
          >{{ $t('Continue') }}</a
        >
      </div>
    </form>
  </section>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';

import Checkbox from '@/components/Checkbox';

export default Vue.extend({
  components: {
    Checkbox,
  },
  data() {
    return {
      symptoms: {
        feverChills: false,
        difficultyBreathing: false,
        cough: false,
        lossOfSenses: false,
        vomitingDiarrhea: false,
        soreThroat: false,
        aching: false,
        fatigue: false,
        headache: false,
        congestion: false,
      },
      noSymptoms: false,
      symptomsSelected: false,
      clearedSymptoms: false,
    };
  },
  computed: mapState(['testingPolicy']),
  watch: {
    noSymptoms() {
      this.manageCheckboxes();
    },
    symptoms: {
      deep: true,
      handler(symptoms) {
        const hasSymptoms = Object.keys(symptoms).some((i) => symptoms[i]);
        this.symptomsSelected = hasSymptoms;
        // unchecks the 'None of the Above' checkbox if the user selects a symptom
        if (hasSymptoms && this.noSymptoms) {
          this.clearedSymptoms = true;
          this.noSymptoms = false;
        }
      },
    },
  },
  methods: {
    manageCheckboxes() {
      // unchecks all symptoms
      if (!this.clearedSymptoms) {
        Object.keys(this.symptoms).forEach((i) => {
          this.symptoms[i] = false;
        });
      } else {
        this.clearedSymptoms = false;
      }
    },
    determineQualification() {
      const numberOfSymptoms = Object.keys(this.symptoms).reduce(
        (sum, key) => (sum += this.symptoms[key] ? 1 : 0),
        0
      );
      const symptomLimit = this.testingPolicy.symptomatic_only ? 1 : 0;
      this.$store.dispatch(
        'updateQualification',
        numberOfSymptoms >= symptomLimit
      );
      this.$router.push('next-steps');
    },
  },
});
</script>
