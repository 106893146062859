<template>
  <div>
    <Triage />
  </div>
</template>

<script>
import Triage from '@/components/Triage/Triage.vue';

export default {
  name: 'TriagePage',
  components: {
    Triage,
  },
};
</script>
